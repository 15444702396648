import styled from "styled-components";

export const TeamHeaderMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 200px;
  padding-bottom: 160px;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 80px;
  ${"" /* min-height: calc(100vh - 100px); */}

  &.webPage {
    min-height: unset;
    padding: 5rem 0;
  }
  &.FrontBottomt {
    padding: 7rem 0;
  }
  &.ELearningBusiness {
    padding: 5rem 0;
  }
  &.DoweMain {
    padding: 10rem 0;
  }
  &.AndroidHeaderMain {
    margin-bottom: 0px;
  }
  &.oddo {
    padding-top: 60px;
  }
  &.WooCommerce {
    padding-top: 80px;
  }
  @media (min-width: 1440px) {
    &.iosHeaderMain {
      padding-top: 115px;
    }
    &.e-commerce {
      padding-top: 115px;
    }
    &.WooCommerce {
      padding-top: 115px;
    }
    margin-bottom: 0;

    &.joomlaMain {
      padding-top: 160px;
    }
  }
  @media (max-width: 1200px) {
    &.iosHeaderMain {
      padding-top: 90px;
    }
    &.e-commerce {
      padding-top: 90px;
    }
    &.WooCommerce {
      padding-top: 90px;
    }
    &.oddo {
      padding-top: 60px;
    }
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    min-height: unset;
    /* padding-bottom: 160px; */

    &.webPage {
      padding: 5rem 0;
    }
    &.ELearningBusiness {
      padding: 1rem 0;
    }
    .shopifySub {
      font-size: 20px;
      line-height: 32px;
    }
    &.shopifyMain {
      gap: 1rem;
      /* padding-bottom: 1rem !important; */
    }
    &.iosHeaderMain {
      padding-top: 130px;
    }
    &.e-commerce {
      padding-top: 130px;
    }
    &.WooCommerce {
      padding-top: 130px;
    }
  }
  @media (max-width: 991px) {
    /* padding-bottom: 140px; */
    padding-top: 140px;
    padding-bottom: 100px;
    margin-bottom: 0px;
    &.DoweMain {
      padding: 8rem 0;
    }
    &.iosHeaderMain {
      padding-top: 100px;
    }
    &.e-commerce {
      padding-top: 100px;
    }
    &.WooCommerce {
      padding-top: 100px;
    }
  }
  @media (max-width: 820px) {
    margin-top: 0rem;
    min-height: unset;
    /* padding-bottom: 160px; */
    svg.BottomLineSvg {
      width: 70%;
    }

    span.EasierSvg {
      right: 86px !important;
      top: 27px;
      svg {
        width: 70%;
      }
    }
    span.UnderStanding {
      top: -12px !important;
      left: -11px !important;

      svg {
        width: 83%;
      }
    }
    &.ELearningBusiness {
      padding: 10rem 0;
    }
    &.TimeExpand {
      padding-bottom: 5rem;
    }
    &.shopifyMain {
      margin-top: 2rem;
    }
  }
  @media (max-width: 768px) {
    span.EasierSvg {
      right: 77px !important;
      top: 27px;
      svg {
        width: 70%;
      }
    }
  }
  @media (max-width: 479px) {
    gap: 1rem;
    padding-bottom: 100px;
    padding-top: 30px;
    margin-top: 0;
    &.pageHead {
      padding-top: 30px;
    }
    &.e-commerce {
      margin-bottom: 0rem;
      padding-bottom: 10px;
      padding-top: 50px;
    }
    &.WooCommerce {
      margin-bottom: 0rem;
      /* padding-bottom: 10px; */
      padding-top: 50px;
    }

    &.oddo {
      padding-top: 1rem !important;
    }
    &.mobileapp {
      margin-bottom: 1rem;
      padding-top: 2rem;
    }
    &.webPage {
      padding: 3rem 0;
    }
    .faqHeading {
      letter-spacing: 0.01px;
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 26px;
    }
    &.FlutterFaq {
      margin-top: 0rem;
    }
    &.webPage {
      margin-bottom: 2rem !important;
    }
    &.TimeExpand {
      padding-bottom: 100px;
    }
    &.iosHeaderMain {
      padding-bottom: 100px;
      padding-top: 30px;
    }
    &.AndroidHeaderMain {
      padding-bottom: 10px;
    }
    span.EasierSvg {
      right: 4px !important;
      top: 22px;
      svg {
        width: 50%;
      }
    }
    span.UnderStanding {
      svg {
        width: 69%;
      }
    }
  }
`;

export const TeamHeadingText = styled.h1`
  letter-spacing: -1.5px;
  margin-top: 0;
  font-size: 84px;
  font-weight: 700;
  line-height: 92px;
  text-align: center;

  &.FlutterFaqTitle {
    font-size: 70px;
  }
  &.business-say {
    display: flex;
  }
  &.BottomSectionTitle {
    font-size: 58px;
    line-height: 76px;
    margin-bottom: 0px;
  }
  &.frequently {
    padding-top: 7rem;
  }
  &.AndroidHeader {
    letter-spacing: -1.5px;
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 84px;
    font-weight: 700;
    line-height: 92px;
  }
  &.EcommerceFaq {
    font-size: 54.49px;
    font-weight: 700;
    line-height: 76px;
    letter-spacing: -1.5px;
  }
  &.ShopifyFaq {
    font-size: 54.49px;
    font-weight: 700;
    line-height: 76px;
    letter-spacing: -1.5px;
  }
  &.restaurant {
    font-size: 84px;
    font-weight: 700;
    line-height: 92px;
    letter-spacing: -1.5px;
    text-align: center;
    margin-bottom: 0px;
  }
  &.app-head {
    font-size: 84px;
    font-weight: 700;
    line-height: 92px;
    letter-spacing: -1.5px;
    text-align: center;
    margin-bottom: 0px;
  }
  &.TimeExpandTitle {
    font-size: 96px;
    line-height: 106px;
  }

  .curveIcon {
    position: absolute;
    left: -2rem;
    bottom: 25px;
  }
  .ServicesIcon {
    position: absolute;
    left: -4rem;
    bottom: 10px;
  }
  .ProductIcon {
    position: absolute;
    left: 3rem;
    bottom: 10px;
  }
  .MagentoRoundOrange {
    left: -2rem;
    bottom: -8px;
  }
  .MagentoIcon {
    top: 30px;
    right: -100px;
  }
  .UI-UX-Round {
    top: -9px;
    left: 0px;
  }
  .ASPBlueRoundLineSvg {
    svg {
      height: revert-layer;
    }
  }
  @media (max-width: 1600px) {
    .WebAppMain {
      top: -27px;
      left: -46px;
    }
    .UI-UX-Round {
      top: -34px;
      left: -16px;
      right: -4px;
    }
    &.joomla {
      font-size: 80px;
      line-height: 90px;
    }
    &.MagentoTitle {
      font-size: 75px;
      line-height: 85px;
    }
    &.Startup-Title {
      font-size: 70px;
      line-height: 80px;
    }
  }
  @media (min-width: 1440px) {
    text-align: center;
    box-sizing: border-box;
    letter-spacing: -1.5px;
    margin-top: 0;
    font-weight: 700;
    font-size: 96px;
    line-height: 106px;
    &.AndroidHeader {
      margin-bottom: 40px;
      font-size: 96px;
      line-height: 106px;
    }
    &.restaurant {
      font-size: 96px;
      font-weight: 700;
      line-height: 108px;
    }
    &.app-head {
      font-size: 96px;
      line-height: 108px;
    }
    .WebAppMain {
      top: -30px;
      left: -46px;
    }
    .MagentoRoundOrange {
      left: -2rem;
      bottom: -8px;
    }
    .MagentoIcon {
      top: 10px;
      right: -100px;
    }
  }

  @media (max-width: 1200px) {
    .WebAppMain {
      top: -25px;
      left: -46px;
    }
    .ServicesIcon {
      position: absolute;
      left: -4rem;
      bottom: 10px;
    }
    .ProductIcon {
      position: absolute;
      left: 1rem;
      top: 100px;
    }
    .curveIcon {
      left: 4rem;
      bottom: 14px;
    }
    .MagentoIcon {
      top: 0px;
      right: -100px;
    }
  }
  @media (max-width: 1024px) {
    letter-spacing: -1.5px;
    margin-top: 0;
    font-size: 84px;
    font-weight: 700;
    line-height: 92px;
    
    &.Mobile-App-USA-Header-H1 {
      font-size: 70px;
      line-height: 80px;
    }
    &.app-head {
      font-size: 84px;
      line-height: 92px;
    }
    &.AndroidHeader {
      margin-bottom: 0;
    }
    &.joomla {
      font-size: 75px;
      line-height: 85px;
    }
    &.SoftwareTesting {
      font-size: 75px;
      line-height: 85px;
    }
    &.OdooSpan {
      left: -88px;
    }

    .aboutSvg {
      top: -16px;
    }
    .roundedSvg {
      top: -8px;
    }
    &.frequently {
      padding-top: 5rem;
    }
    .good {
      bottom: -10px !important;
      right: 3rem;
      svg {
        width: 80px;
      }
    }
    &.TimeExpandTitle {
      font-size: 70px;
      line-height: 85px;
    }
    &.BottomSectionTitle {
      font-size: 46px;
      line-height: 60px;
    }
    &.FlutterFaqTitle {
      font-size: 46px;
      line-height: 60px;
    }
    &.crossInside {
      padding-top: 0;
    }
    &.ShopifyFaq {
      font-size: 46px;
      line-height: 60px;
    }
    .ServicesIcon {
      position: absolute;
      left: -4rem;
      bottom: 10px;
    }
    .ProductIcon {
      position: absolute;
      left: 1rem;
      top: 100px;
    }
    &.WebAppMain {
      top: 0px;
      left: -46px;
    }
    &.MagentoTitle {
      font-size: 67px;
      line-height: 77px;
    }
    .UI-UX-Round {
      top: -34px;
      left: -16px;
      right: -4px;
    }
  }
  @media (max-width: 991px) {
    font-size: 63px;
    line-height: 70px;

    &.Mobile-App-USA-Header-H1 {
      font-size: 50px;
      line-height: 60px;
    }
    &.Startup-Title {
      font-size: 50px;
      line-height: 60px;
    }
    &.AndroidHeader {
      font-size: 62px;
      font-weight: 700;
      line-height: 70px;
      letter-spacing: -1.5px;
      text-align: center;
    }
    &.restaurant {
      font-size: 62px;
      font-weight: 700;
      line-height: 70px;
      letter-spacing: -1.5px;
      text-align: center;
    }
    &.app-head {
      font-size: 62px;
      line-height: 70px;
    }
    .curveIcon {
      left: 2rem;
      bottom: 0px;
    }
    .MagentoRoundOrange {
      left: -2rem;
      bottom: -6px;
    }
    &.MagentoTitle {
      font-size: 55px;
      line-height: 65px;
    }
    .MagentoIcon {
      top: 0px;
      right: -80px;
    }
    .OdooSpan {
      bottom: -12px !important;
      left: -88px;
      svg {
        width: 70vw;
      }
    }
    .ProductIcon {
      position: absolute;
      left: 1rem;
      top: 70px;
      svg {
        width: 80%;
      }
    }
  }
  @media (max-width: 820px) {
    &.TimeExpandTitle {
      font-size: 60px;
      line-height: 70px;
    }
    &.BottomSectionTitle {
      font-size: 36px;
      line-height: 46px;
      padding-top: 0;
    }
    &.FlutterFaqTitle {
      font-size: 36px;
      line-height: 46px;
    }

    .ServicesIcon {
      position: absolute;
      left: -5rem;
      bottom: 0px;
    }
    .ProductIcon {
      position: absolute;
      left: 1rem;
      bottom: 0px;
      svg {
        width: 80%;
      }
    }
    &.ShopifyFaq {
      font-size: 34px;
      line-height: 46px;
      letter-spacing: 0;
    }
    .WebAppMain {
      top: -15px;
      left: -20px;
    }
    .SupportIcon {
      bottom: 0px;
    }
  }
  @media (max-width: 798px) {
    .WebAppMain {
      top: -15px;
      left: -20px;
    }
    .ServicesIcon {
      position: absolute;
      left: -5rem;
      bottom: 0px;
    }
    .ProductIcon {
      position: absolute;
      left: 1rem;
      bottom: 0px;
    }
    &.MagentoTitle {
      font-size: 52px;
      line-height: 62px;
    }
    .AspdotNetIcon {
      right: 5rem;
      svg {
        width: 100px;
        height: 40px;
      }
    }
    &.PythonTitle {
      font-size: 55px;
      line-height: 65px;
    }
  }

  @media (max-width: 720px) {
    &.AndroidHeader {
      font-size: 45px;
      line-height: 55px;
    }
  }
  @media (max-width: 600px) {
    letter-spacing: -0.1px;
    font-size: 24px;
    line-height: 34px;

    &.Mobile-App-USA-Header-H1 {
      font-size: 25px;
      line-height: 35px;
    }
    &.Startup-Title {
      font-size: 25px;
      line-height: 35px;
    }
    &.EcommerceFaq {
      letter-spacing: 0 !important;
    }
    &.ShopifyFaq {
      letter-spacing: 0.5px;
    }
    .curveIcon {
      left: 2rem;
      bottom: -10px;
      svg {
        height: 60px !important;
        width: 60px !important;
      }
    }
    .MagentoRoundOrange {
      left: -1rem;
      bottom: -2px;
    }
    &.SoftwareTesting {
      font-size: 26px;
      line-height: 36px;
    }
  }
  @media (max-width: 430px) {
    padding-top: 0rem;
    margin-bottom: 0px;
    .OdooSpan {
      bottom: -30px !important;
      left: -50px;
      svg {
        width: 70vw !important;
      }
    }
    &.frequently {
      padding-top: 0rem;
    }
    &.oddoFaq {
      padding-top: 3rem;
      letter-spacing: 0;
    }
    .joomlaSvg {
      width: 50%;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &.AndroidHeader {
      font-size: 34px;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: -1px;
      text-align: center;
      svg {
        width: 100%;
      }
    }
    &.ProgressiveTitle {
      font-size: 30px;
      line-height: 40px;
    }
    &.EcommerceFaq {
      font-size: 24px;
      line-height: 34px;
    }
    &.ShopifyFaq {
      font-size: 24px;
      line-height: 34px;
    }
    &.restaurant {
      font-size: 34px;
      line-height: 40px;
      letter-spacing: -1px;
    }
    &.app-head {
      font-size: 34px;
      line-height: 40px;
      letter-spacing: -1px;
    }

    &.TimeExpandTitle {
      font-size: 34px;
      line-height: 40px;
    }
    &.BottomSectionTitle {
      font-size: 26px;
      line-height: 40px;
    }
    &.FlutterFaqTitle {
      font-size: 24px;
      line-height: 34px;
    }
    &.ELearningBusinessTitle {
      font-size: 25px;
      line-height: 35px;
    }
    .ServicesIcon {
      position: absolute;
      left: -2.5rem;
      bottom: 0px;
      width: 30%;
      height: 40%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .ProductIcon {
      position: absolute;
      left: -1rem;
      top: 20px;
      svg {
        width: 50%;
      }
    }
    &.WebAppMain {
      top: 0px;
      left: -14px;
    }
    .WebAppMain {
      top: -10px;
      left: -20px;
    }
    &.MagentoTitle {
      font-size: 30px;
      line-height: 40px;
    }
    .MagentoIcon {
      top: 0px;
      right: -58px;
      svg {
        width: 50%;
      }
    }
    .ProgressiveSvg {
      svg {
        width: 50%;
      }
    }
    &.Vue-js-Titel {
      font-size: 30px;
      line-height: 40px;
    }
    &.PythonTitle {
      font-size: 30px;
      line-height: 40px;
    }
    &.PythonWebTitle {
      font-size: 35px;
      line-height: 45px;
    }
    .ASPBlueRoundLineSvg {
      svg {
        height: 100%;
      }
    }
    .AspdotNetIcon {
      right: 1rem;
      svg {
        width: 70px;
        height: 40px;
      }
    }
    .SupportIcon {
      right: -100px;
    }
  }
  @media (max-width: 414px) {
    .ServicesIcon {
      position: absolute;
      left: -2.5rem;
      bottom: 0px;
    }
  }

  @media (max-width: 400px) {
    &.Modernization {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media (max-width: 390px) {
    /* .OdooSpan {
      top: -30px;
      left: -30px;
    } */

    .joomlaSvg {
      width: 50%;
      right: -4rem;
    }
    .ServicesIcon {
      position: absolute;
      left: -2.5rem;
      bottom: 0px;
    }
    .curveIcon {
      left: 2rem;
      bottom: -10px;
    }
    &.MagentoTitle {
      font-size: 28px;
      line-height: 38px;
    }
    .AspdotNetIcon {
      right: 0rem;
    }
    &.PythonTitle {
      font-size: 28px;
      line-height: 37px;
    }
    &.restaurant {
      font-size: 28px;
      line-height: 37px;
    }
    &.Healthcare {
      font-size: 28px;
      line-height: 37px;
    }
  }
  @media (min-width: 390px) and (max-width: 415px) {
    .CRMSvg {
      right: -12px;
      left: -5px;
      bottom: -3px;
    }
    .IOTSvg {
      right: -12px;
      left: -10px;
      bottom: 2px;
    }
  }
  @media (max-width: 375px) {
    .OdooSpan {
      left: -26px;
      right: 52px;
    }
    .curveIcon {
      left: 2rem;
      bottom: -10px;
    }
    .MagentoIcon {
      top: -8px;
      right: -50px;
    }
    &.PythonTitle {
      font-size: 27px;
      line-height: 37px;
    }
    &.PythonWebTitle {
      font-size: 30px;
      line-height: 40px;
    }
    &.AspHeader {
      font-size: 28px;
      line-height: 38px;
    }
  }
  @media (max-width: 350px) {
    .curveIcon {
      left: -6px;
      bottom: -10px;
    }
  }
`;
export const FAQMain = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;

  @media (min-width: 1440px) {
    /* padding-top: 80px; */
  }
  @media (max-width: 820px) {
    .HomeTwoEngineeredButton {
      left: 8rem;
    }
  }
  @media (max-width: 768px) {
    .HomeTwoEngineeredButton {
      left: 7rem;
    }
  }
  @media (max-width: 479px) {
    .front-end-heading {
    }
    .HomeTwoEngineeredButton {
      left: 0rem;
    }
  }
`;
export const FAQMainSub = styled.div`
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-radius: 14px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.82fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 40px;
  display: grid;
  border-radius: 14px;
  border: 1px solid var(--border-color);

  .step__count {
    display: flex;
    width: 38px;
    height: 38px;
    background: #c8c8c8;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 10;
    transition: all 300ms cubic-bezier(0.48, 0.01, 0.42, 1.37);
    background: #fb2979 !important;

    &.active {
      transform: scale(1.5);
      background: #4caf50;
    }
  }
  .greenLine {
    background: #fb2979;
    z-index: 2;
    position: absolute;
    content: "";
    top: 20px;
    left: 17px;
    width: 2px;
    height: calc(100% + 90px);

    &.line_height {
      height: calc(100% + 20px);
    }
  }

  @media screen and (min-width: 1440px) {
    margin-bottom: 24px;
    padding: 48px;
  }

  @media screen and (max-width: 1366px) {
    .step__count {
      left: 0px;
      width: 28px;
      height: 28px;
    }
  }
  @media screen and (max-width: 1024px) {
    .step__count {
      left: -110px;
      width: 28px;
      height: 28px;
    }
  }
  @media screen and (max-width: 991px) {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    border-radius: 12px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 14px;
    padding: 32px;
    .step__count {
      left: -70px;
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
    display: block;
  }
  @media screen and (max-width: 479px) {
    margin-bottom: 16px;
    padding: 24px;
    .step__count {
      top: -60px;
      left: -35px;
      width: 15px;
      height: 15px;
    }
    &.HomeTwoEngineeredFAQMainSub {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
`;
export const FAQMainSubColQ = styled.div`
  padding-right: 50px;
  display: inline-block;
  position: relative;

  @media screen and (min-width: 1440px) {
    letter-spacing: -1px;
    padding-right: 120px;
    display: inline-block;
    position: relative;
  }
  @media (min-width: 991px) and (max-width: 1025px) {
    .firstIcon {
      top: 33px;
      left: 30%;
    }
    .secIcon {
      bottom: 15px;
      width: 100%;
      right: 20px !important;
    }
    .thrdIcon {
      top: 3px;
      left: -9px;
    }
  }
  @media (max-width: 820px) {
    .screen {
      right: 52% !important;
    }
  }
  @media (max-width: 600px) {
    padding-right: 0px;
    .firstIcon {
      top: 33px;
      left: 40%;
    }
    .secIcon {
      bottom: 15px;
      width: 50%;
      right: 50px !important;
    }
    .thrdIcon {
      top: -2px;
      left: -20px;
    }
  }
`;
export const FAQTitle = styled.h3`
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  text-align: left;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 28px;
  line-height: 38px;

  &.font-Oswald {
    font-family: Oswald;
    font-size: 48px;
    line-height: 54px;
  }

  @media screen and (min-width: 1440px) {
    letter-spacing: -1px;
    font-size: 36px;
    line-height: 48px;

    &.font-Oswald {
      font-size: 48px;
      line-height: 54px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 32px;
    &.font-Oswald {
      font-size: 32px;
      line-height: 42px;
    }
  }

  @media (max-width: 475px) {
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.01px;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 26px;

    &.font-Oswald {
      font-size: 20px;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 479px) {
    .box-wide__title {
      letter-spacing: 0.01px;
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 26px;
    }
  }
`;
export const FAQMainSubColA = styled.div`
  letter-spacing: -0.1px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;

  @media screen and (min-width: 1440px) {
    font-size: 26px;
    line-height: 42px;
    letter-spacing: -0.1px;
    margin-bottom: 0;
    margin-top: 0;
  }
  @media screen and (max-width: 991px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 475px) {
    padding: 0;
    box-sizing: border-box;
    margin: 0;

    font-size: 14px;
    line-height: 22px;
  }
`;
export const FrequentlyMobileList = styled.div`
  &.caselist {
    ul {
      padding-top: 1rem;

      li {
        font-size: 14px;
        border-bottom: 1px solid #e8e8e8;
        font-weight: 300;
        padding: 5px 0;
        list-style: disc !important;
        color: #7b7b7b;
        display: list-item;
      }
    }
  }
  ul {
    padding-top: 1rem;

    li {
      display: flex;
      font-size: 20px;
      font-weight: 300;
      gap: 1rem;
      padding: 5px 0;
      align-items: center;
      @media (max-width: 600px) {
        font-size: 16px;
      }
      svg {
        min-width: 20px;
        margin-top: 6px;
      }
    }
  }
`;
export const TeamInnerText = styled.p`
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
  letter-spacing: -0.2px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 930px;
  a {
    &:hover {
      color: #fff;
      background: #f4004c;
    }
  }
  &.restaurant {
    font-weight: 300;
    font-size: 25.3906px;
    line-height: 37px;
    letter-spacing: -0.2px;
  }
  @media screen and (min-width: 1440px) {
    padding-top: 50px;
    /* padding-bottom: 50px; */

    &.StrugglingText {
      font-size: 26px;
      line-height: 36px;
    }
    &.codeigniter {
      padding-top: 0 !important;
    }
    .p {
      letter-spacing: -0.2px;
      margin-bottom: 40px;
      font-size: 26px;
      line-height: 36px;
    }
  }
  @media (max-width: 1024px) {
    &.beforeText {
      padding-bottom: 3rem;
    }
    br {
      display: block;
    }
    &.TimeExpandText {
      font-size: 20px;
      line-height: 32px;
    }
    &.StrugglingText {
      max-width: 670px;
      margin: 0 auto;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 1.5rem;
      line-height: 30px;

      br {
        display: none;
      }
    }
    &.YourSuccessText {
      font-size: 20px;
      line-height: 32px;
    }
    &.BottomSectionText {
      font-size: 20px;
      line-height: 32px;
    }
    &.OutstandingText {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 820px) {
    &.TimeExpandText {
      font-size: 1rem;
      line-height: 25px;
    }
    &.StrugglingText {
      max-width: 616px;
      font-size: 16px;
      line-height: 26px;
      /* padding-top: 10px !important; */
      margin-bottom: 0rem;
    }

    &.YourSuccessText {
      max-width: 420px;
      font-size: 16px;
      line-height: 26px;
    }
    &.BottomSectionText {
      font-size: 16px;
      line-height: 26px;
    }
    &.OutstandingText {
      margin-bottom: 2rem;
    }
  }
  @media (max-width: 769px) {
    font-size: 14px;
    line-height: 24px;
    max-width: 600px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 1rem;

    br {
      display: none;
    }
  }
  &.TimeExpandText {
    /* br {
      display: block;
    } */
  }
  @media (max-width: 479px) {
    padding-top: 20px;
    /* padding-bottom: 20px; */
  }
  @media (max-width: 430px) {
    &.TimeExpandText {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0rem;
    }
    &.StrugglingText {
      font-size: 14px;
      line-height: 22px;
      padding-top: 40px;
    }
    &.YourSuccessText {
      font-size: 14px;
      line-height: 22px;
    }
    &.BottomSectionText {
      font-size: 14px;
      line-height: 22px;
    }

    &.OutstandingText {
      /* margin-bottom: 20px !important; */
    }
  }
`;
export const ThreeDayTrial = styled.button`
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 1px;
`;
export const ScaleButton = styled.div`
  max-width: max-content;
  padding: 10px 15px;
  border-radius: 7px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 12px;
  letter-spacing: 0.09em;

  &.getButton {
    background: #ff8e5e;
    // color: #1d1e22;
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }

  @media (max-width: 769px) {
    font-size: 14px;
    margin-top: 1rem;
  }
  @media (max-width: 430px) {
    &.EngagementModel-btn {
      margin: 0 auto;
    }
  }
`;

export const MiddleContantMain = styled.div`
  /* background: #000; */
  color: #fff;
  border-radius: 14px;
  margin: auto;
  padding: 3rem;
  text-align: center;
  /* border: 0.5px solid #fff3; */
  //max-width: 900px;
  min-height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: none !important;
  &.erenberg {
    background: #f6f4f2;
    // color: #1d1e22;
    margin-top: 8rem;
  }
  @media (min-width: 1440px) {
    //max-width: 1076px;
  }
  @media (max-width: 1024px) {
    &.erenberg {
      margin-top: 5rem;
    }
  }
  @media (max-width: 820px) {
    ${"" /* width: 90%; */}
    ${"" /* padding: 2rem; */}
    &.erenberg {
      margin-top: 0rem;
    }
    min-height: 430px;
  }
  @media (max-width: 768px) {
    &.erenberg {
      margin-top: 5rem;
    }
  }

  @media (max-width: 600px) {
    padding: 1.5rem;
    width: 100%;

    // &.erenberg {
    //   margin-top: 0;
    // }
  }
`;

export const MiddleContantText = styled.p`
  font-family: Merriweather;
  color: #e9e9e9;
  font-size: 40px;
  font-weight: 300;
  line-height: 58px;
  padding: 0 2rem;
  svg {
    width: 100%;
    height: auto;
  }
  @media (max-width: 1366px) {
    font-size: 28px;
    line-height: 45px;
  }
  @media (max-width: 1024px) {
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
    padding: 0 0rem;

    .smallRoundIcon {
      bottom: -23px;
    }
  }
  @media (max-width: 991px) {
    font-size: 22px;
    line-height: 34px;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 600px) {
    .smallRoundIcon {
      bottom: -29px;
    }
  }
  &.erenberg {
    // color: #1d1e22;
  }
`;

export const MiddleContantImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 0;
  img {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 778px) {
    img {
      width: 48px;
      height: 48px;
    }
  }
`;
export const MiddleContantInner = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  padding: 1rem 0;
  font-size: 14px;
  font-weight: 400;
  color: #a8a8a8;

  &.erenberg {
    color: #909090;

    svg {
      path {
        fill: #000 !important;
      }
    }
  }
`;
export const FormSectionMain = styled.div`
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;

  @media screen and (min-width: 1440px) {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  @media screen and (max-width: 991px) {
    display: block;
  }
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const FormSectionCol = styled.div``;

export const PerfectMatchMain = styled.div`
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const PerfectMatchMiddle = styled.div``;
export const PerfectMatchCol = styled.div`
  &.col-right {
    justify-content: flex-end;
    flex-flow: column;
    display: flex;
    //left: -45px;
    margin-left: -45px;
    position: relative;
  }
  @media (max-width: 1024px) {
    margin-left: 0px !important;
  }
  @media (max-width: 600px) {
    &.col-right {
      margin-left: 0px;
    }
  }
`;
export const PerfectMatchCard = styled.div`
  border-radius: 16px;
  padding: 48px 42px;
  background: var(--background-color-dark);
  border-radius: 16px;

  .innerArraw {
    svg {
    }
  }
  &.card-right {
    border: 0px solid var(--text-color-dark);
  }
  &.card-left {
    color: var(--text-color);
  }
  @media (max-width: 991px) {
    padding: 28px;
  }
`;
export const PerfectMatchCardInner = styled.div`
  letter-spacing: -0.2px;
  font-size: 20px;
  line-height: 32px;
  display: block;
  @media screen and (min-width: 1440px) {
    letter-spacing: -0.2px;
    font-size: 26px;
    line-height: 42px;
  }
  @media screen and (max-width: 991px) {
    letter-spacing: -0.2px;
    font-size: 16px;
    line-height: 26px;
  }
  @media screen and (max-width: 767px) {
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 479px) {
    font-size: 16px;
    line-height: 26px;
  }
`;
export const PerfectMatchIcon = styled.div`
  &.feelsvg {
    padding-right: 80px;
  }
`;
export const StrugglingMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    &.custom-app {
      padding: 4rem 0;
    }
    &.portfolioMobile {
      padding: 3rem 0;
    }
    &.before {
      padding: 0rem 0;
    }
  }
  @media (max-width: 991px) {
    &.custom-app {
      padding: 2rem 0;
    }
  }
  @media (max-width: 820px) {
    /* padding: 5rem 0; */
    &.CareersstrugglingMain {
      padding-bottom: 9rem !important;
    }
  }
  @media (max-width: 768px) {
    padding: 0rem 0;
    &.CareersstrugglingMain {
      padding-bottom: 7rem !important;
    }
  }

  @media (max-width: 479px) {
    margin-bottom: 30px;
    &.CareersstrugglingMain {
      padding-bottom: 9rem !important;
    }
  }
  @media (max-width: 430px) {
    /* margin-top: 3rem; */
    /* padding: 1rem 0 !important; */
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    &.before {
      padding: 7rem 0;
    }
    &.frontend {
      margin-top: 4rem;
      margin-bottom: 1rem;
    }
    &.BusinessNeedsSliderMain {
      margin-top: 0rem;
    }
  }
`;
export const ExploreCodinater = styled.div`
  @media (max-width: 1024px) {
    .expolore-main {
      margin-top: 2rem;
    }
    .explore-card {
      height: 330px;
    }
  }
  @media (max-width: 600px) {
    .explore-card {
      height: 250px;
    }
  }
`;
export const StrugglingInnerCard = styled.div`
  @media (max-width: 600px) {
    .strugglingCard {
      padding: 0 0 1rem;
    }
    .insideCard {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;
export const StrugglingHeading = styled.h2`
  font-size: 58px;
  font-weight: 600;
  text-align: center;
  line-height: 68px;
  margin-bottom: 0px;
  position: relative;

  .starIcon {
    top: -40px;
    left: -50px;
    width: 100%;
  }
  .arrowIcon {
    right: -55px;
    bottom: -25px;
  }
  .rounderIcon {
    svg {
      width: 100%;
      height: auto;
    }
  }
  .curveIcon {
    position: absolute;
    bottom: -7px;
    left: 0;

    svg {
      width: 100%;
      height: auto;
    }
  }
  svg.chooseSvg {
    width: 100%;
  }
  svg.GenerateSvg {
    width: 100%;
  }
  .starIconTwo {
    top: -50px;
    left: 343px;
    width: 100%;
  }

  .obstacles {
    top: 0;
  }
  .choose {
    bottom: 0;
    left: 0;
  }

  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: 38px;
    &.YourSuccess {
      font-size: 46px;
      line-height: 60px;
    }
    &.crossInner {
      padding-top: 0 !important;
    }
    .Generate {
      top: -48px;
      right: -7px;
    }
    .starIconTwo {
      top: -55px;
      left: 163px;
    }
    &.StrugglingHeading {
      font-size: 46px;
      line-height: 56px;
    }
    .obstacles {
      top: -17px;
      right: -41px;
    }
    .choose {
      bottom: -9px;
    }
    .compare-arrow {
      right: -36px;
      bottom: -22px;
      svg {
        width: 200px;
      }
    }
    .arrowIcon {
      svg {
        width: 130px;
      }
    }
    .starIcon {
      top: -35px;
      left: -35px;
      width: 100%;
    }
    .success {
      top: -7px;
      right: -8px;
      left: -9px;
      svg {
        width: 100%;
      }
    }
    .value {
      bottom: -10px !important;
      svg {
        width: 100%;
      }
    }
    .partnerships {
      right: -30px;
      top: -18px !important;
      svg {
        width: 100%;
      }
    }
  }
  @media (max-width: 991px) {
    max-width: 480px;
    margin: 0 auto;
    font-size: 34px;
    line-height: 46px;

    max-width: unset;
    &.BusinessNeedsSliderTitel {
      max-width: 485px;
    }
    &.Industry {
      max-width: 100%;
    }
    &.YourSuccess {
      padding-top: 0px;
      font-size: 34px;
      line-height: 46px;
      max-width: 350px;
      br {
        display: none;
      }
    }
    &.StrugglingHeading {
      font-size: 34px;
      line-height: 46px;
      /* max-width: 560px; */
      br {
        display: none;
      }
    }
  }
  @media (max-width: 820px) {
    max-width: unset;
    /* margin-top: 3rem; */

    .insideText {
      padding-top: 1.5rem;
    }
    &.Outstanding {
      margin-top: 0rem;
      padding-top: 0rem;
    }
  }
  @media (max-width: 769px) {
    font-size: 30px;
    line-height: 40px;
    .starIconTwo {
      left: 137px;
    }
    &.TurnDevelopment {
      font-size: 20px;
    }
    .eCommerce {
      padding: 1rem 1.5rem;
    }
  }
  @media (max-width: 600px) {
    font-size: 24px;
    padding-top: 0;
    .arrowIcon {
      right: 0;
    }
    .compare-arrow {
      svg {
        width: 160px;
      }
    }
    &.eCommerce {
      margin-top: 2.5rem;
    }
    .insideText {
      padding-top: 1.5rem;
    }
  }
  @media (max-width: 430px) {
    &.TurnDevelopment {
      font-size: 28px;
      line-height: 25px;
    }
    &.RealDiscover {
      padding-top: 0;
    }
    &.StrugglingHeading {
      font-size: 28px;
      line-height: 36px;
      padding-top: 0 !important;
    }
    &.YourSuccess {
      font-size: 26px;
      line-height: 36px;
    }
  }
  @media (max-width: 390px) {
    &.Industry {
      max-width: 360px;
    }
    .native-hidden {
      display: none;
    }
  }
`;

export const StrugglingMiddle = styled.div`
  padding-top: 2rem;
  width: 90%;
  margin: auto;

  @media (max-width: 1024px) {
    padding-top: 3rem;
    padding-bottom: 4rem;

    &.app_inner_sec {
      padding-bottom: 20rem;
    }
  }
  @media (max-width: 820px) {
    width: 100%;
    padding-top: 3rem;

    &.findTeam {
      padding-top: 0;
    }
  }
  @media (max-width: 600px) {    
    padding-bottom: 0rem;
  }
`;
export const StrugglingMiddleInner = styled.div``;
export const StrugglingBox = styled.div`
  background: #f6f6f6;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  padding: 3rem;
  width: 588px;
  min-height: 670px;
  position: relative;
  &.perfect-match-card {
    background: #f7f7f7;
    border-radius: 16px;
    border: 0px solid #d9d9d9;
    padding: 48px 42px;
    width: 588px;
    min-height: 610px;
    position: relative;
  }
  &.Careersstruggling {
    width: 480px;
  }
  .innerArraw {
    position: absolute;
    bottom: 48px;

    svg {
      height: 33px;
      width: 33px;
    }
  }

  &.teamSearch {
    width: 559px;
  }
  &.sec {
    margin-top: 22rem;
    transform: scaleX(-1);
    float: right;
    background: #fff;
    border: 1px solid #fff;
  }
  &.trd {
    //clear: both;
    margin-top: 90rem;
    margin-bottom: 10rem;
  }
  &.PremiumSupportMain {
    margin-bottom: 13rem;
  }

  @media (max-width: 1180px) {
    .teamSearch {
      right: -85% !important;
    }
    &.app_inner {
      top: 19rem;
    }
  }
  @media (max-width: 1024px) {
    width: 50%;
    min-height: 560px;
    padding: 2rem;

    &.sec {
      margin-top: 20rem;
    }
    &.trd {
      margin-top: 72rem;
      /* margin-bottom: 20rem; */
    }
    &.Careersstruggling {
      width: 400px;
    }
    &.PremiumSupportMain {
      margin-bottom: 15rem;
    }
  }
  @media (max-width: 820px) {
    .absolute {
      top: 35rem;
    }
    &.trd {
      margin-bottom: 12rem;
    }
    &.Careersstruggling {
      width: 360px;
    }
    &.PremiumSupportMain {
      margin-bottom: 13rem;
    }
    .KeyFeaturesTwo {
      top: 688px !important;
    }
    .KeyFeaturesThree {
      top: 780px !important;
    }
  }
  @media (max-width: 769px) {
    width: 50%;
    min-height: 450px;
    padding: 1rem 1rem 5rem;

    .innerArraw {
      bottom: 25px;
    }

    &.sec {
      margin-top: 16rem;
      float: right;
      border: 1px solid #d9d9d9;
    }
    &.trd {
      margin-top: 60rem;
    }
    &.PremiumSupportMain {
      margin-bottom: 13rem;
      margin-top: 45rem;
    }
    &.teamSearch {
      background: #fff;
      width: 100%;
      min-height: 300px !important;
    }
    .absolute {
      top: 15rem;
    }
    .last {
      top: 13.3rem;
    }
    .findTeamCenter {
      top: 18rem;
      right: 0px;
    }
    &.Careersstruggling {
      width: 350px;
    }
    .KeyFeaturesTwo {
      top: 336px !important;
    }
    .KeyFeaturesThree {
      top: 450px !important;
    }
    &.StandardSupport {
      margin-top: 15rem;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    min-height: unset;
    padding: 1rem 1rem 5rem;

    .innerArraw {
      bottom: 25px;
    }
    &.sec {
      margin-top: 16rem;
      float: unset;
      border: 1px solid #d9d9d9;
    }
    &.trd {
      margin-top: 17rem;
    }
    &.teamSearch {
      background: #fff;
      width: 100%;
      min-height: 300px !important;
    }
    .absolute {
      top: 15rem;
    }
    .last {
      top: 13.3rem;
    }
    .findTeamCenter {
      top: 18rem;
      right: 0px;
    }
    .KeyFeaturesTwo {
      top: 173px !important;
    }
  }
  @media (max-width: 430px) {
    &.sec {
      margin-top: 16.5rem;
      border: 1px solid #d9d9d9;
    }
    &.trd {
      margin-top: 18rem;
      margin-bottom: 13rem;
    }
    .absolute {
      top: 18rem;
    }
    .last {
      top: 16.3rem;
    }
    .findTeamCenter {
      top: 18rem;
    }
    &.Careersstruggling {
      width: 100%;
    }
    &.PremiumSupportMain {
      margin-bottom: 20rem !important;
      margin-top: -2.2rem !important;
    }
    .KeyFeaturesThree {
      top: 224px !important;
    }
    .KeyFeatures-One-main {
      top: 12.5rem;
    }
  }
  @media (max-width: 414px) {
    .KeyFeaturesThree {
      top: 225px !important;
    }
    &.PremiumSupportMain {
      margin-top: 17.3rem;
    }
    .KeyFeaturesTwo {
      top: 200px !important;
    }
  }
  @media (max-width: 390px) {
    .center {
      top: 21.3rem;
    }
    .last {
      top: 19.7rem;
    }
    &.trd {
      margin-top: 17.3rem;
    }
    .KeyFeaturesTwo {
      top: 227px !important;
    }
    .absolute {
      top: 16rem;
    }
    &.PremiumSupportMain {
      margin-top: 0.5rem !important;
      margin-bottom: 15rem !important;
    }
    .KeyFeaturesThree {
      top: 252px !important;
    }
    .KeyFeatures-One-main {
      top: 13.5rem;
    }
  }
  @media (max-width: 375px) {
    .absolute {
      top: 21.5rem;
    }
    .last {
      top: 20rem;
    }
    &.struggling-cards-big {
      /* margin-top: 17rem; */
    }
    &.trd {
      margin-top: 18.8rem;
    }
    .KeyFeatures-One-main {
      top: 16rem;
    }
  }
`;
export const StrugglingBoxHeading = styled.div`
  font-size: 34px;
  font-weight: 600;
  line-height: 46px;

  &.inner {
    font-size: 32px;
  }
  &.sec {
    transform: scaleX(-1);
  }

  @media (max-width: 1024px) {
    font-size: 22px;
    line-height: 32px;

    &.inner {
      font-size: 22px;
      line-height: 32px;
    }
  }

  @media (max-width: 769px) {
    font-size: 22px;
    line-height: 28px;
    padding-top: 10px;

    &.inner {
      font-size: 22px;
      line-height: 28px;
      padding-top: 5px;
    }
  }
`;
export const StrugglingBoxInner = styled.div`
  font-size: 22px;
  font-weight: 400;
  line-height: 40px;
  padding-top: 1.5rem;
  &.feelp {
    color: var(--text-color-dark);
    padding-top: 0rem;
  }
  &.inner {
    font-size: 20px;
  }
  &.sec {
    transform: scaleX(-1);
  }
  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 32px;

    &.inner {
      font-size: 16px;
      line-height: 32px;
    }
  }

  @media (max-width: 769px) {
    font-size: 16px;
    line-height: 26px;
    padding-top: 10px;

    &.inner {
      font-size: 16px;
      line-height: 26px;
      padding-top: 10px;
    }
  }
`;
export const SolutionBoxInner = styled.div`
  position: absolute;
  background: #fcc5e8;
  padding: 3rem;
  border-radius: 16px;
  bottom: -421px;
  right: -330px;
  width: 588px;
  color: #000;
  &.feel2 {
    position: relative;
    background: #ff0080;
    bottom: 330px;
    right: -510px;
    max-width: 600px;
    color: #fff;
  }
  &.secCard {
    background: #1d1e22;
    div {
      color: #fff;
    }
  }
  &.innerSearch {
    width: 85%;
  }
  &.KeyFeatures-One {
    background: #ffa2d1;
  }

  @media (max-width: 1024px) {
    width: 350px;
    padding: 2rem;
    bottom: -495px;
    &.KeyFeatures-One {
      bottom: -430px;
    }
    &.KeyFeatures-Two {
      bottom: -375px;
    }
    &.KeyFeatures-Three {
      bottom: -292px;
    }
    &.Find-Your-Team-box {
      bottom: -438px;
    }
  }
  @media (max-width: 820px) {
    padding: 2rem;
    bottom: -140px;
    right: -230px;

    &.career {
      right: -125px;
    }
    &.KeyFeatures-One {
      bottom: -175px;
    }
    &.KeyFeatures-Two {
      bottom: -50px;
    }
    &.KeyFeatures-Three {
      bottom: 20px;
    }
    &.Find-Your-Team-box {
      bottom: -140px;
    }
  }

  @media (max-width: 768px) {
    padding: 2rem;
    bottom: -320px;
    right: -150px;

    &.KeyFeatures-One {
      bottom: -330px;
    }
    &.KeyFeatures-Two {
      bottom: -350px;
    }
    &.KeyFeatures-Three {
      bottom: -220px;
    }
    &.Find-Your-Team-box {
      bottom: -320px;
    }
  }

  @media (max-width: 600px) {
    position: unset;
    padding: 1rem;
    margin-top: 1.5rem;

    svg {
      width: 100%;
    }

    &.innerSearch {
      width: 100%;
    }
    &.benefits-card-two {
      margin-top: 0rem;
    }
    &.struggling-cards-two {
      margin-top: 0rem;
    }
  }
  @media (max-width: 540px) {
    &.struggling-cards-two {
      margin-top: 4.5rem;
    }
    &.struggling-cards-four {
      margin-top: 4.5rem;
    }
    &.SolutionLast {
      margin-top: 4.5rem;
    }
  }
  @media (max-width: 430px) {
    &.struggling-cards-two {
      margin-top: 2.3rem;
    }
    &.struggling-cards-four {
      margin-top: 2.3rem;
    }
    &.KeyFeatures-Two {
      margin-top: 8.3rem !important;
    }
    &.KeyFeatures-One {
      margin-top: 8.3rem !important;
    }
    &.KeyFeatures-Three {
      margin-top: 7rem !important;
    }
    &.SolutionLast {
      margin-top: 4rem;
    }
    &.StrugglingLast {
      margin-top: 2.3rem;
    }
    &.areersstruggling-cards-two {
      margin-top: -3rem;
    }
  }
  @media (max-width: 414px) {
    &.KeyFeatures-Two {
      margin-top: 7.3rem !important;
    }
    &.struggling-cards-four {
      margin-top: 4rem;
    }
    &.StrugglingLast {
      margin-top: 4rem;
    }
  }
  @media (max-width: 390px) {
    &.struggling-cards-two {
      margin-top: 3.3rem;
    }
    &.struggling-cards-four {
      margin-top: 5rem;
    }
    &.SolutionLast {
      margin-top: 5rem;
    }
    &.areersstruggling-cards-two {
      margin-top: -3rem;
    }
    &.KeyFeatures-One {
      margin-top: 2.3rem;
    }
    &.KeyFeatures-Three {
      margin-top: 10rem !important;
    }
  }
  @media (max-width: 375px) {
    &.struggling-cards-two {
      margin-top: 0rem;
    }
    &.LastSolution {
      margin-top: 1rem;
    }
    &.areersstruggling-cards-two {
      margin-top: -6.5rem;
    }
    &.KeyFeatures-One {
      margin-top: 5.3rem !important;
    }
    &.KeyFeatures-Three {
      margin-top: 9rem !important;
    }
    &.struggling-cards-four {
      margin-top: 0rem;
    }
    &.SolutionLast {
      margin-top: 0rem;
    }
  }
`;
export const StrugglingBoxOneIcon = styled.div`
  position: absolute;
  top: 89px;
  right: -100%;
  &.feelsvg {
    top: 700px;
    left: 0;
  }
  &.CareersIcon {
    right: -144%;
  }
  &.sec {
    top: 0;
    transform: scaleX(-1);
  }
  &.trd {
    top: 0;
  }
  &.teamSearch {
    right: -125%;
  }
  &.PremiumSvg {
    right: -70%;
  }
  @media (max-width: 1024px) {
    &.teamSearch {
      display: none;
    }
    width: 100%;

    svg {
      width: 100%;
    }
    &.CareersIcon {
      right: -110%;
    }
    &.PremiumSvg {
      right: -110%;
    }
  }
  @media (max-width: 991px) {
    &.PremiumSvg {
      right: -100%;
      width: 60%;
      top: -90px;
    }
    &.StandardSvg {
      width: 80%;
      top: -110px;
    }
  }
  @media (max-width: 769px) {
    top: -7px;

    svg {
      width: 100%;
    }
    &.PremiumSvg {
      right: -80%;
    }
  }
  @media (max-width: 600px) {
    position: unset;

    svg {
      display: none;
    }
  }
`;
export const AIMLDesignAIMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 18px;
  width: 100%;
`;
export const AIMLDesignAIRow = styled.div`
  display: flex;
  background: #f8f8f8;
  padding: 100px 100px;

  @media (max-width: 1024px) {
    padding: 50px 50px;
  }
  @media (max-width: 820px) {
    gap: 20px;
  }
  @media (max-width: 768px) {
    padding: 25px 25px;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
export const AIMLDesignAICol = styled.div`
  display: flex;

  flex-direction: column;
`;
export const AIMLList = styled.ul`
  letter-spacing: -0.26px;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 16px;
  line-height: 24px;
  list-style-type: none;
  overflow: visible;
  &.form-list {
    font-size: 16px;
    line-height: 24px;
      li {
        margin-bottom:20px;
        &:before {
          top: 0px;
          bottom: 0px;
          margin: auto;
          left: 0;
          width: 19px;
          height: 13px;
          filter: invert(0) brightness(1);
        }
      }
  }
  &.feel-list {
    font-size: 26px;
    line-height: 36px;
    margin-left: 35px;
    li {
      display: flex;
      column-gap: 15px;
      margin-bottom: 20px;
      align-items: baseline;
    }
    ul {
      li {
        &:before {
          top: 0px;
          bottom: 0px;
          margin: auto;
          left: 0;
          width: 19px;
          height: 13px;
          filter: invert(0) brightness(10);
          //background: url(https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/right-arrow-orange.svg) center center no-repeat;
        }
      }
    }
  }
  &.perfect {
    font-size: 26px;
    line-height: 36px;
    margin-left: 35px;
    li {
      display: flex;
      column-gap: 15px;
      margin-bottom: 20px;
    }
    ul {
      li {
        &:before {
          top: 0px;
          bottom: 0px;
          margin: auto;
          left: 0;
          width: 19px;
          height: 13px;
          filter: invert(0) brightness(1);
          //background: url(https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/right-arrow-orange.svg) center center no-repeat;
        }
      }
    }
  }
  ul {
    list-style: unset;
    padding-top: 10px;
    li {
      padding: 0px 0px 0px 35px;
      list-style: none;
      position: relative;
      margin-bottom: 20px;
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        width: 19px;
        height: 13px;
        ${
          "" /* background: url(https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/right-arrow-orange.svg) center center no-repeat; */
        }
        filter: invert(1) brightness(0);
        background-size: cover;
      }
    }
  }
  @media (min-width: 1440px) {
    letter-spacing: -0.26px;
    font-size: 16px;
    line-height: 24px;
    ul {
      li {
        padding: 0 0 0 35px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    &.perfect {
      font-size: 16px;
      line-height: 26px;
    }
    &.feel-list {
      font-size: 16px;
      line-height: 26px;
    }
  }
  @media screen and (max-width: 991px) {
    letter-spacing: 0;
    font-size: 16px;
    line-height: 26px;
    ul {
      li {
        padding: 0px 0px 0px 35px;
      }
    }

    &.perfect {
      font-size: 16px;
      line-height: 26px;
    }
    &.feel-list {
      font-size: 16px;
      line-height: 26px;
    }
  }
  @media (max-width: 600px) {
    ul {
      /* padding-left: 1rem; */

      li {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
        margin-top: 1rem;
      }
    }
  }
`;
export const ForSolvingMain = styled.div`
  ${
    "" /* display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center; */
  }
  align-items: start;
  gap: 24px;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 820px) {
    align-items: stretch;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
export const ForSolvingItems = styled.div`
  background: ${(props) => (props.bg ? props.bg : ``)};
  color: ${(props) => (props.color ? props.color : ``)};
  //width: 48%;
  border-radius: 12px;
  padding: 2.5rem;
  min-height: 299px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  svg {
    ${
      "" /* margin-top: 0.5rem;
    margin-bottom: 2rem; */
    }
  }

  @media (max-width: 820px) {
    padding: 2rem;
  }

  @media (max-width: 769px) {
    //width: 45%;
    min-height: 250px;
    &.mern-fso {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  @media (max-width: 479px) {
    width: 100%;
    min-height: 185px;
  }
  @media (max-width: 430px) {
    padding: 1rem;
  }
`;

export const ForSolvingHeader = styled.div`
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 30px;
  color: ${(props) => (props.color ? props.color : ``)};

  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 15px;
  }
  @media (max-width: 820px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (max-width: 430px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
export const ForSolvingInner = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : ``)};

  &.active {
    font-family: Merriweather !important;
    position: unset;
    width: 100%;
    font-size: 18px;
  }
  &.mErn {
    position: unset;
    width: 100%;
    font-family: Merriweather !important;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
  }

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
    &.active {
      font-size: 13px;
    }
  }
  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 26px;
    &.mErn {
      font-size: 14px;
      line-height: 25px;
    }
  }
  @media (max-width: 769px) {
    font-size: 16px;
    &.active {
      font-size: 13px;
    }
  }
  @media (max-width: 600px) {
    &.mErn {
      font-size: 12px;
      line-height: 22px;
    }
  }
`;
export const LastCardKnack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  ${
    "" /* position: absolute;
  bottom: 30px; */
  }
  ${"" /* width: calc(100% - 90px); */}

  @media (max-width: 769px) {
    ${
      "" /* bottom: 20px;
    width: calc(100% - 80px); */
    }
  }
  @media (max-width: 600px) {
    ${
      "" /* bottom: 20px;
      width: calc(100% - 60px); */
    }

    svg {
      margin: 0;
    }
  }
`;
export const LastCardSpan = styled.span`
  color: ${(props) => (props.color ? props.color : ``)};
`;
export const LastCardKnackLeft = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    margin: 0;
  }
  @media (max-width: 1024px) {
    margin-top: 1rem !important;
  }
  @media (max-width: 820px) {
    font-size: 10px;
    gap: 7px;
  }

  @media (max-width: 769px) {
    justify-content: center;
  }
  @media (max-width: 600px) {
    justify-content: flex-start;
    margin-top: 0.5rem !important;
  }
  @media (max-width: 375px) {
    .client-name {
      display: none;
    }
  }
`;
export const OurClientMain = styled.div`
  padding: 0rem 0;
  width: 100%;
  // overflow: hidden;
  &.AndroidHeader {
    padding-top: 7rem;
    padding-bottom: 0;
  }
  @media (max-width: 1200px) {
    .FlutterCardSub {
      padding-bottom: 4rem;
    }
    &.FlutterCard {
      margin-top: 0px !important;
    }
  }
  @media (max-width: 1024px) {
    &.AndroidHeader {
      padding-top: 0rem;
    }
    .codenater-icon {
      bottom: -15px !important;
      width: 100%;

      svg {
        width: 100%;
      }
    }
  }
  @media (max-width: 991px) {
    padding-top: 0rem;
    &.AndroidHeader {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
    .FlutterCardSub {
      padding-bottom: 4rem;
    }
  }
  @media (max-width: 820px) {
    &.firstSection {
      padding-bottom: 0;
    }
  }
  @media (max-width: 768px) {
    &.client {
      padding-top: 0;
    }
    &.AndroidHeader {
      padding-bottom: 0rem;
    }
    &.FlutterCard {
      margin-top: 0px !important;
    }
    .FlutterCardSub {
      padding-bottom: 4rem;
    }
  }
  @media (max-width: 600px) {
    &.Partnerships {
      margin-top: 2.5rem !important;
    }
    .oddoInner {
      font-size: 14px;
      line-height: 26px;
      text-align: center;
    }
    .codenater-icon {
      bottom: -10px !important;
    }

    &.client {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
    &.AndroidHeader {
      padding: 1rem 0;
    }
    .merriweather {
      font-size: 14px;
      line-height: 26px;
    }
  }

  @media (max-width: 430px) {
    &.Flutter {
      padding: 0rem 0;
      /* margin-top: 5rem !important; */
    }
    &.OutstandingMain {
      padding-top: 0rem;
    }
  }
`;

export const OurClientCard = styled.div``;
export const OurClientCardItems = styled.div`
  width: 88%;
  margin: auto;
  background: ${(props) => (props.bg ? props.bg : `#1D1E22`)};
  color: ${(props) => (props.color ? props.color : ``)};
  display: flex;
  //align-items: flex-start;
  padding: 3.5rem 2.5rem;
  border-radius: 14px;
  min-height: 601px;
  margin-bottom: 5rem;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;

  &.UIUX {
    gap: 50px;
  }
  &:hover {
    scale: 1.03;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  }
  &.SaasDevelopmentSec {
    padding-bottom: 2rem;
  }
  @media (max-width: 1024px) {
    width: 100%;
    ${"" /* flex-wrap: wrap; */}
    padding: 2rem;
    margin-bottom: 5rem;
    min-height: auto;
  }

  @media (max-width: 991px) {
    margin-bottom: 2rem;
  }
  @media (max-width: 769px) {
    width: 100%;
    ${"" /* flex-wrap: wrap; */}
    gap: 10px;
  }
  @media (max-width: 600px) {
    margin-bottom: 2rem;
    flex-wrap: wrap;
    &.UIUX {
      gap: 10px;
    }
  }
`;
export const OurClientLeft = styled.div`
  width: 60%;
  padding-right: 10rem;
  &.Lflip-horizontal {
    width: 50%;
    padding-right: 0rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 1180px) {
    padding-right: 4rem;
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: 0;
  }
  @media (max-width: 600px) {
    &.Lflip-horizontal {
      width: 100%;
    }
  }
  @media (max-width: 430px) {
    margin-bottom: 2rem;
  }
`;
export const OurClientCardHeading = styled.h3`
  font-size: 26px;
  font-weight: 600;
  line-height: 38px;

  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: 38px;
    margin-right: 0px;
  }
  @media (max-width: 820px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 430px) {
    font-size: 18px;
    line-height: 26px;
  }
`;
export const OurClientCardInner = styled.p`
  font-size: 26px;
  font-weight: 300;
  line-height: 36px;
  padding-top: 4rem;

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 32px;
    padding-top: 2rem;
    margin-right: 81px;
  }
  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 26px;
    margin-right: 32px;
  }
  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 22px;
    margin-right: 0px;
  }
`;

export const OurClientCardInnerLi = styled.li``;
export const CaseSliderMain = styled.div`
  padding: 0rem 0;
  width: 100%;
  // overflow: hidden;
  &.AndroidHeader {
    padding-top: 7rem;
    padding-bottom: 0;
  }
  @media (max-width: 991px) {
    padding-top: 2rem;
    &.AndroidHeader {
      padding-top: 0rem;
      padding-bottom: 6rem;
    }
  }
  @media (max-width: 820px) {
    &.firstSection {
      padding-bottom: 0;
    }
  }
  @media (max-width: 768px) {
    &.client {
      padding-top: 0;
    }
    &.AndroidHeader {
      padding-bottom: 0rem;
    }
  }
  @media (max-width: 600px) {
    &.Partnerships {
      margin-top: 2.5rem !important;
    }

    &.client {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
    &.AndroidHeader {
      padding: 1rem 0;
    }
    .merriweather {
      font-size: 14px;
      line-height: 26px;
    }
  }
  @media (max-width: 430px) {
    &.Flutter {
      padding: 0rem 0;
      margin-top: 5rem !important;
    }
  }
`;

export const CaseSliderCard = styled.div`
  margin-top: 10rem;
  margin-bottom: 10rem;

  @media (max-width: 1024px) {
    margin-top: 8rem;
  }
  @media (max-width: 820px) {
    margin-top: 4rem;
  }
  @media (max-width: 600px) {
    margin-top: 14rem;
    margin-bottom: 0rem;
  }
`;
export const CaseSliderCardItems = styled.div`
  width: 100%;
  max-width: 1118px;
  background: ${(props) => (props.bg ? props.bg : `#1D1E22`)};
  color: ${(props) => (props.color ? props.color : `#fff`)};
  display: flex;
  padding: 48px;
  border-radius: 14px;
  min-height: 400px;
  margin-bottom: 8rem;
  gap: 74px;

  &.CaseSliderCardMain {
    display: flex !important;
  }
  &.UIUX {
    gap: 50px;
  }

  @media (min-width: 1440px) {
    width: 100%;
    max-width: 1200px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 940px;
    padding: 2rem;
    margin-bottom: 5rem;
    min-height: 350px;
    gap: 32px;
  }

  @media (max-width: 769px) {
    width: 100%;
    max-width: 700px;
  }
  @media (max-width: 600px) {
    margin-bottom: 0rem;
    flex-wrap: wrap;
    padding: 24px;
    &.UIUX {
      gap: 10px;
    }
    &.CaseSliderCardMain {
      height: 630px;
    }
  }
  @media (max-width: 400px) {
    &.CaseSliderCardMain {
      height: 670px;
    }
  }
`;
export const CaseSliderLeft = styled.div`
  width: 60%;
  padding-right: 10rem;
  &.Lflip-horizontal {
    width: 50%;
    padding-right: 0rem !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  @media (max-width: 1180px) {
    padding-right: 4rem;
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: 0;
  }
  @media (max-width: 600px) {
    &.Lflip-horizontal {
      width: 100%;
    }
  }
  @media (max-width: 430px) {
    margin-bottom: 2rem;
  }
`;
export const CaseSliderCardHeading = styled.div`
  letter-spacing: -1px;
  font-size: 34px;
  line-height: 42px;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: 38px;
    margin-right: 81px;
  }
  @media (max-width: 820px) {
    font-size: 22px;
    line-height: 32px;
    margin-right: 32px;
  }
  @media (max-width: 430px) {
    font-size: 18px;
    line-height: 26px;
    margin-right: 0px;
  }
`;
export const CaseSliderCardInner = styled.div`
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  padding-top: 4rem;

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 32px;
    padding-top: 2rem;
    margin-right: 81px;
  }
  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 26px;
    margin-right: 32px;
  }
  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 22px;
    margin-right: 0px;
  }
`;
export const CaseSliderRight = styled.div`
  width: 50%;

  &.Rflip-horizontal {
    width: 50%;
  }
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    &.Rflip-horizontal {
      width: 100%;
    }
    &.CaseSliderRight {
      top: -135px;
      position: relative;
      width: 100%;
    }
  }
`;
export const CaseSliderRightHeading = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  padding-bottom: 2.5rem;
`;
export const CaseSliderImage = styled.div`
  &.CaseSliImg {
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
  @media (max-width: 1366px) {
    img {
      max-width: 572px;
    }
  }
  @media (max-width: 1180px) {
    img {
      max-width: 500px;
    }
  }
  @media (max-width: 1024px) {
    img {
      border-radius: 14px;
      width: 100%;
      max-width: 405px;
    }
    &.CaseSliImg {
      img {
        max-width: 405px;
      }
    }
  }
  @media (max-width: 600px) {
    &.CaseSliImg {
      img {
        left: 0px;
        top: 20px;
        max-width: 100%;
        width: 100%;
        position: relative;
      }
    }
  }
`;

export const ViewCaseStudyBtn = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  text-transform: uppercase;
  padding-top: 40px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: #ff0080;
  }

  svg {
    height: 15px;
    width: 15px;
  }
  @media (max-width: 600px) {
    padding-top: 32px;
  }
`;
export const OurClientRight = styled.div`
  width: 40%;
  &.Rflip-horizontal {
    width: 50%;
  }
  &.saas-horizontal {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 4rem;
    img {
      margin-top: 0rem !important;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    &.Rflip-horizontal {
      width: 100%;
    }
  }
`;
export const OurClientRightHeading = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  padding-bottom: 2.5rem;
  @media (max-width: 778px) {
    padding-bottom: 0.5rem;
  }
`;
export const OurClientImage = styled.div`
  img {
    max-width: unset;
    height: unset;
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.22);
  }
  &.howwehelp {
    img {
      /* max-width: unset; */
      /* height: unset; */
      box-shadow: unset;
      position: relative;
      left: -100px;
      margin-top: 3rem;
      /* width: 300px; */
    }
  }
  @media (max-width: 1366px) {
    img {
      max-width: 495px;
    }
  }
  @media (max-width: 1180px) {
    img {
      /* max-width: 359px; */
    }
    &.howwehelp {
      img {
        left: 0px;
      }
    }
  }
  @media (max-width: 1024px) {
    img {
      border-radius: 14px;
      width: 100%;
    }
  }
  @media (max-width: 789px) {
    &.howwehelp {
      img {
        margin-top: 2rem;
      }
    }
  }
  @media (max-width: 600px) {
    &.howwehelp {
      img {
        left: 0px;
        top: 20px;
      }
    }
  }
`;
export const BenefitsMain = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
  &.OurReactJS {
    padding-bottom: 5rem;
    @media (max-width: 390px) {
      padding-bottom: 0;
    }
  }

  @media (max-width: 1180px) {
    .Bene-fits-Card {
      width: 40%;
    }
    &.NodeJsServices {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
  }
  @media (max-width: 991px) {
    .Bene-fits-Card {
      width: 50%;
    }
    &.SocialMediaServices {
      margin-top: 6rem;
    }
  }
  @media (max-width: 768px) {
    &.Bene-fits-Main {
      display: flex;
      justify-content: center;
    }
    .Bene-fits-Card {
      width: unset;
    }
    &.Whychoose {
      padding-top: 10rem !important;
    }
  }
  @media (max-width: 479px) {
    flex-wrap: wrap;
    padding-top: 0rem;
    &.whyus {
      padding-top: 2rem;
    }
    .Bene-fits-Card {
      width: 100%;
    }
    &.OurReactJS {
      display: flex;
      flex-wrap: wrap;
    }

    &.Bene-fits-Main {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 0rem;
    }
    &.Whychoose {
      padding-top: 3rem !important;
    }
    &.SocialMediaServices {
      margin-top: 2rem;
    }
    .Bene-fits-Card-BottomP {
      font-size: 10px;
    }
  }
`;
export const BenefitsTopHead = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  padding-bottom: 1rem;
  max-width: 588px;

  &.forSvgicon {
    padding-bottom: 5rem;
  }
  &.mobileapp {
    padding-bottom: 7rem;
    padding-top: 2rem;
  }
  &.what-we-do {
    font-size: 24px;
    text-align: left;
    line-height: 62px;
  }
  &.benefits {
    text-align: left;
    padding-bottom: 1rem;
    text-transform: uppercase;
    /* line-height: 62px; */
  }
  &.flutterApp {
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 2rem;
  }
  &.softwareText {
    font-weight: 400;
    // color: #1d1e22;
    padding-top: 1rem;
    padding-bottom: 7rem;
  }
  @media (min-width: 1440px) {
    &.benefits {
      padding-bottom: 1rem;
    }
    &.forSvgicon {
      padding-bottom: 7rem;
    }
  }
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 25px;
    padding-bottom: 7rem;

    &.what-we-do {
      font-size: 24px;
      text-align: left;
      padding-bottom: 0rem;
    }
  }

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 26px;
    text-align: start;
    padding-bottom: 10px;
  }
  @media (max-width: 479px) {
    &.softwareText {
      padding-bottom: 30px !important;
    }
  }
  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    &.what-we-do {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
    }
    &.benefits {
      text-align: center;
    }
    &.mobileapp {
      padding-bottom: 2rem;
    }
  }
`;
export const BenefitsHeading = styled.h2`
  font-size: 47px;
  font-weight: 600;
  line-height: 57px;
  padding-bottom: 7rem;
  &.mobileapp {
    padding-bottom: 0rem;
  }
  &.font-Oswald {
    font-family: Oswald;
  }
  &.xamarineapp {
    font-size: 54px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -1.5px;
    padding-bottom: 2rem;
  }
  &.pro-re-design {
    padding-bottom: 1rem;
  }
  svg.AppDevelopSvg {
    margin-left: 0px;
  }
  .AppDevelop {
    bottom: -17px;
    left: 0;
  }
  .blinking-cursor {
    color: #000;
    display: inline-flex;
    width: 3px;
    background-color: #000;
    margin-left: 10px;
  }
  svg.ObstaclesSvg {
    margin: 0;
  }
  // .say-about {
  //   top: 143px;
  // }
  &.serviceText {
    line-height: 79px;
  }
  &.ExpertiseInMern {
    font-size: 47px;
    font-weight: 600;
    line-height: 55px;
    padding-bottom: 7rem;
    max-width: 588px;
  }
  .obstacles {
    top: -15px;
  }
  @media (min-width: 1440px) {
    .Webapp {
      top: -25px;
      left: -1px;
    }
    font-size: 54px;
    line-height: 68px;
    font-weight: 700;
  }
  @media (max-width: 1550px) {
    .Webapp {
      top: -24px;
      left: -6px;
    }
  }
  @media (max-width: 1366px) {
    font-size: 43px;
    svg.AppDevelopSvg {
      width: 97%;
    }
    &.ExpertiseInMern {
      font-size: 46px;
      line-height: 56px;
    }
  }
  @media (max-width: 1180px) {
    font-size: 30px;
    line-height: 40px;

    &.hire-mobile-businesses {
      line-height: 40px;
    }
    .AppDevelop {
      bottom: -15px;
    }
    &.what-we-do-text {
      letter-spacing: -1px;
      font-size: 46px;
      line-height: 56px;
    }
    &.ExpertiseInMern {
      font-size: 47px;
    }
    .say-about {
      top: 100px;
      svg {
        width: 50%;
      }
    }
    &.serviceText {
      line-height: 60px;
    }
    &.ELearningServiceTitle {
      line-height: 50px;
    }
    &.benefits-text {
      font-size: 37px;
      line-height: 47px;
    }
  }
  @media (max-width: 1024px) {
    letter-spacing: 0;
    font-size: 30px;
    line-height: 40px;

    .obstacles {
      top: -20px;
    }
    &.pro-re-design {
      font-size: 37px;
      line-height: 50px;
      padding-bottom: 8px;
    }
    &.benefits-text {
      font-size: 35px;
      line-height: 45px;
      br {
        display: none;
      }
    }
    &.flutterApp {
      line-height: 40px;
    }
    &.ExpertiseInMern {
      letter-spacing: 0;
      font-size: 30px;
      line-height: 40px;
    }

    .Webapp {
      top: -20px;
    }
  }
  @media (max-width: 991px) {
    font-size: 24px;
    line-height: 34px;
    &.pro-re-design {
      font-size: 23px;
      line-height: 35px;
    }
    &.Bene-fits-Left-Title {
      font-size: 30px;
      line-height: 40px;
    }

    &.what-we-do-text {
      font-size: 25px;
      line-height: 30px;
    }
    &.ExpertiseInMern {
      font-size: 26px;
      line-height: 36px !important;
    }
    .AppDevelop {
      bottom: -12px;
    }
    &.serviceText {
      font-size: 19px;
      line-height: 35px;
    }
    .benefits-text {
      font-size: 26px;
      line-height: 36px !important;
    }
    &.What-startup {
      font-size: 27px;

      .say-about {
        top: 73px;
      }
    }
    &.flutterApp {
      font-size: 22px;
      line-height: 30px;
    }
    .Webapp {
      top: -12px;
      left: -6px;
    }
  }
  @media (max-width: 778px) {
    font-size: 32px;
    line-height: 46px;
    padding-bottom: 2rem;
    line-height: 50px;

    &.ELearningServiceTitle {
      line-height: 35px;
    }
    &.Bene-fits-Left-Title {
      font-size: 30px;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
    }
    .say-about {
      top: 70px;
      svg {
        width: 45%;
      }
    }
    &.What-startup {
      line-height: 40px;
    }
    &.hire-mobile-businesses {
      line-height: 30px;
    }
  }
  @media (max-width: 736px) {
    font-size: 23px;
    line-height: 33px;
  }
  @media (max-width: 479px) {
    letter-spacing: -0.5px;
    font-size: 26px;
    line-height: 36px;
    padding-bottom: 30px;
    padding-top: 1rem;
    text-align: center;
    br {
      display: none;
    }
    &.Bene-fits-Left-Title {
      font-size: 25px;
      text-align: center;
      margin-top: 2rem;
      padding-bottom: 2rem;
    }
    svg.AppDevelopSvg {
      display: block;
    }
    .ObstaclesSvg {
      display: block;
      width: 70%;
    }
    .obstacles {
      transform: scaleX(-1);
      left: -93px;
    }
    .highly {
      transform: scaleX(-1);
      left: -160px;
    }
    .WhatourSvg {
      left: -130px;
    }
    &.WhatOurTitle {
      br {
        display: none;
      }
    }
    &.ExpertiseInMern {
      font-size: 24px;
      line-height: 34px;
      padding-bottom: 30px;
      padding-top: 0;
    }
    .WhatourSvg {
      left: -110px;
    }
    .Webapp {
      svg {
        display: block !important;
      }
    }
  }
`;
export const BenefitsLeftSide = styled.div`
  width: 60%;
  /* padding-right: 6rem; */
  position: sticky;
  top: 115px;

  svg {
    ${"" /* margin-left: 3rem; */}
  }
  &.whyus50 {
    width: 50%;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
  
  &.startup-video {
    width: 50%;

    video {
      max-width: 73%;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      min-height: 338px;
      object-fit: cover !important;
    }
  }
  &.app-development {
    padding-right: 5rem;
    svg {
      margin: auto;
    }
  }
  @media (min-width: 1440px) {
  }
  @media (max-width: 1366px) {
    &.startup-video {     

      video {
        max-width: 100%;     
        min-height: 400px;
        
      }
    }
  }
  @media (max-width: 1180px) {
    padding-right: 4rem;

    &.Bene-fits-Left-Side {
      width: 100%;
      padding-right: 0rem;
    }
    &.Bene-fitsSub {
      padding-right: 0rem;
    }
    &.codinater-stickey {
      padding-right: 3rem;
    }
  }
  @media (max-width: 1024px) {
    padding-right: 1rem;
    width: 50%;

    &.Bene-fits-Left-Side {
      width: 100%;
      padding-right: 0rem;
    }
  }

  @media (max-width: 991px) {
    padding-right: 1rem;
    width: 50%;
    &.startup-video {     

video {
  max-width: 100%;     
  min-height: 266px;
  
}
}

    &.app-development {
      padding-right: 4rem;
    }
    &.cardStickey {
      width: 60%;
    }

    &.Bene-fits-Left-Side {
      width: 50%;
      padding-right: 0rem;
    }
    .BenefitsIcon {
      margin-left: 0rem;
      width: 70%;
    }
    &.career {
      padding-right: 5rem;
    }
  }
  @media (max-width: 736px) {
    &.app-development {
      padding-right: 3rem;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
    position: unset;

    &.video_section {
      width: 100%;
    }

    &.cardStickey {
      width: 100%;
    }

    &.whyus50 {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    svg {
      margin-left: 0;
      width: 100%;
    }
  }
  @media (max-width: 479px) {
    svg {
      display: none;
    }
    &.career {
      padding-right: 0rem;
      text-align: center;
    }
    &.app-development {
      padding-right: 0rem;
    }
  }
  @media (max-width: 390px) {
    img {
      width: 50%;
      margin: 0 auto;
    }
  }
`;
export const BenefitsCardMain = styled.div`
  width: 46%;
  //font-family: "Merriweather";
  &.beauty {
    width: 60%;
    max-width: 620px;
  }
  &.ReactJsCustom {
    max-width: 550px;
  }
  &.ReactJsWhatBusinesses {
    width: 100%;

    .cars {
      border: 1px solid #eeeeee;
    }
  }
  &.MySquardRightBottom {
    width: 100%;
  }
  .parallax_scroll {
    display: flex;
    bottom: -279px;
    left: -100px;
    width: 100%;
    height: calc(100% + 583px);
  }
  @media (max-width: 1366px) {
    &.ReactJsCustom {
      width: 38%;
    }
    .parallax_scroll {
      display: flex;
      bottom: -216px;
      left: -80px;
      width: 100%;
      height: calc(100% + 407px);
      position: absolute;
    }
  }
  @media (max-width: 1180px) {
    width: 50%;
    &.ReactJsCustom {
      width: 50%;
    }
    &.OurReactJSRight {
      width: 40%;
    }
  }
  @media (max-width: 1024px) {
    width: 50%;

    &.ReactJsCustom {
      width: 50%;
    }
    &.OurReactJSRight {
      width: 40%;
    }
  }
  @media (max-width: 991px) {
    width: 50%;

    &.cardStickey {
      width: 50%;
    }

    &.ReactJsCustom {
      width: 63%;
    }
  }
  @media (max-width: 768px) {
    width: 50%;

    &.OurReactJSRight {
      width: 50%;
    }
  }
  @media (max-width: 479px) {
    width: 100%;
    &.OurReactJSRight {
      width: 100%;
    }
    &.ReactJsCustom {
      width: 100%;
    }
  }
`;
export const BenefitsCardItems = styled.div`
  background: ${(props) => (props.bg ? props.bg : `transparent`)};
  color: ${(props) => (props.color ? props.color : ``)};
  border-radius: 16px;
  padding: 50px 50px;
  margin-bottom: 25px;
  border: 1px solid #ffffff5c;

  &.beautysub {
    padding: 0px;
  }
  &.ecommerce {
    /* height: 780px;
    max-height: 780px; */
    @media (max-width: 991px) {
      /* height: 480px;
      max-height: max-content; */
    }
    @media (max-width: 600px) {
      /* height: auto;
      max-height: max-content; */
    }
  }
  &.ourReactJs {
    margin-bottom: 2rem;
  }

  &.MySquardRightBottomCar {
    padding: 1rem 1rem !important;
    margin-bottom: -8rem;
  }
  @media (max-width: 1200px) {
    &.ecommerce {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &.ourReactJs {
    @media (max-width: 1024px) {
      margin-bottom: 0;
      margin-top: 1rem;
      padding: 1.5rem 1.5rem;
    }
    @media (max-width: 778px) {
      margin-bottom: 0;
      margin-top: 1rem;
    }
  }

  @media (max-width: 820px) {
    &.MySquardRightBottomCar {
      margin-bottom: -6rem;
    }
  }
  @media (max-width: 820px) {
    padding: 1.5rem 1.5rem;
  }
  @media (max-width: 600px) {
    margin-bottom: 1rem;
    &.ecommerce {
      padding: 1rem;
    }
  }
`;
export const BenefitsCardItemsShopify = styled.div`
  background: ${(props) => (props.bg ? props.bg : `#1D1E22`)};
  color: ${(props) => (props.color ? props.color : ``)};
  border-radius: 16px;
  padding: 50px 50px;
  margin-bottom: 25px;
  border: 1px solid #ffffff5c;
  min-height: 300px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  &.beautysub {
    padding: 0px;
  }
  &.ecommerce {
    /* height: 780px;
    max-height: 780px; */
    @media (max-width: 991px) {
      /* height: 480px;
      max-height: max-content; */
    }
    @media (max-width: 600px) {
      /* height: auto;
      max-height: max-content; */
    }
  }
  &.ourReactJs {
    margin-bottom: 2rem;
  }

  &.MySquardRightBottomCar {
    padding: 1rem 1rem !important;
    margin-bottom: -8rem;
  }
  @media (min-width: 1440px) {
    min-height: 350px;
  }
  @media (max-width: 1200px) {
    &.ecommerce {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &.ourReactJs {
    @media (max-width: 1024px) {
      margin-bottom: 0;
      margin-top: 1rem;
      padding: 1.5rem 1.5rem;
    }
    @media (max-width: 778px) {
      margin-bottom: 0;
      margin-top: 1rem;
    }
  }

  @media (max-width: 820px) {
    &.MySquardRightBottomCar {
      margin-bottom: -6rem;
    }
  }
  @media (max-width: 820px) {
    padding: 1.5rem 1.5rem;
  }
  @media (max-width: 600px) {
    margin-bottom: 1rem;
    min-height: 240px;
    &.ecommerce {
      padding: 1rem;
    }
  }
`;
export const BenefitsCardHeading = styled.h3`
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 15px;
  }
  @media (max-width: 820px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (max-width: 430px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
export const BenefitsCardInner = styled.p`
  font-size: 20px;
  line-height: 30px;
  padding-top: 1rem;
  font-weight: 300;

  &.OurServicesText {
    font-size: 24px;
    padding-top: 0;
  }
  @media (min-width: 1440px) {
  }
  @media (max-width: 1366px) {
    &.OurServicesText {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;

    &.OurServicesText {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 26px;
  }

  @media (max-width: 769px) {
    font-size: 16px;
    line-height: 26px;
  }
  &.MySquardBottomText {
    @media (max-width: 820px) {
      font-size: 12px !important;
      line-height: 22px;
    }
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 24px;
    &.customBottomText {
      br {
        display: none;
      }
    }
  }
`;
export const BenefitsCardMiddle = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  padding-top: 1rem;

  @media (max-width: 820px) {
    padding-top: 2rem;
    font-size: 20px;
    line-height: 32px;
  }

  @media (max-width: 769px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const BenefitsCardList = styled.div`
  padding-top: 1rem;

  ul {
    list-style: disc;
    padding-left: 1.1rem;

    li {
      padding: 5px 0;
    }
  }
`;
export const BottomCardMain = styled.div`
  background: #1d1e22;
  border-radius: 14px;
  max-width: 486px;
  /* min-height: 312px; */
  padding: 2rem;
  /* margin-top: 3rem; */
  &.CaseSlider {
    background: #ffcae4;
    margin-top: 0rem;
    position: absolute;
    top: 250px;
    max-width: 505px;
  }
  @media (max-width: 1024px) {
    max-width: 435px;
    padding: 1.5rem;
  }
  @media (max-width: 820px) {
    max-width: 480px;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 1.5rem;
    &.CaseSlider {
      top: 410px;
    }
  }
`;
export const BottomCardText = styled.div`
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  /* color: var(--border-color-dark); */
  color: white;
  letter-spacing: 0.2px;
  font-family: Merriweather;

  @media (max-width: 778px) {
    padding-right: 0;
    font-size: 18px;
    line-height: 30px;
    &.CaseSliderReviewText {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (max-width: 430px) {
    padding-right: 0;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 22px;
  }
`;
export const BottomSectionMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  &.frontendBottom {
    padding: 5rem 0;
  }
  .DownArrowIcon {
    left: 160px;
    top: -100px;
  }
  @media (max-width: 1366px) {
    .DownArrowIcon {
      left: 4%;
      top: -10%;
    }
  }
  @media (max-width: 1200px) {
    .DownArrowIcon {
      left: 50px;
    }
  }
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    margin-top: 0;

    .DownArrowIcon {
      left: 10%;
      top: -10%;
      svg {
        width: 60px;
      }
    }
    .DownArrowIconSvg {
      height: 70%;
    }
  }

  @media (max-width: 991px) {
    .DownArrowIcon {
      left: 6%;
      top: -10%;
    }
    /* &.We-were-main {
      padding-top: 5rem !important;
      margin-top: 0rem !important;
    } */
  }
  @media (max-width: 769px) {
    flex-wrap: wrap;
    margin-top: 0;

    &.We-were-main {
      padding-top: 0rem !important;
    }
    .DownArrowIcon {
      left: 0%;
      top: 8%;
      width: 25%;
    }
  }
  @media (max-width: 768px) {
    .DownArrowIcon {
      left: 4%;
      top: -10%;
    }
  }
  @media (max-width: 736px) {
    .DownArrowIcon {
      display: none;
    }
  }
  @media (max-width: 479px) {
    &.frontendBottom {
      padding: 0rem 0;
    }
  }
`;
export const WhatBusinessMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;

  .headingText {
    font-size: 17px;
    font-weight: 300;
    line-height: 27px;
    font-family: Merriweather;
  }
  @media (max-width: 1024px) {
    .business-cards {
      margin-top: 0 !important;
    }
  }
  @media (max-width: 479px) {
    gap: 1rem;
  }
`;

export const WhyCompaniesCard = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 5rem;

  &.hiring {
    flex-wrap: wrap;
    justify-content: center;
    margin: 10rem 0 5rem;
  }
  @media (max-width: 1024px) {
    margin-top: 2rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 769px) {
    gap: 1rem;
    margin-top: 2rem;
  }
  @media (max-width: 600px) {
    &.hiring {
      margin: 5rem 0 0rem;
    }
  }
`;
export const WhyCompaniesItems = styled.div`
  min-height: 478px;
  background: ${(props) => (props.bg ? props.bg : `#1D1E22`)};
  color: ${(props) => (props.color ? props.color : `#fff`)};
  width: 30%;
  border-radius: 14px;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.5px solid #ccc;

  &.hiring {
    width: 386px;
  }
  @media (max-width: 1024px) {
    width: 48.3%;
  }
  @media (max-width: 820px) {
    width: 47%;
  }

  @media (max-width: 769px) {
    width: 48%;
    min-height: 400px;

    &.hiring {
      width: 48%;
      min-height: 400px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;

    &.hiring {
      width: 100%;
    }
  }
`;
export const WhyCompaniesHeading = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    min-width: 78px;
  }
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;
export const WhyCompaniesInner = styled.div`
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  min-height: 108px;

  &.hiring {
    font-size: 22px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;

    &.hiring {
      font-size: 20px;
    }
  }
`;
export const WeCompareMain = styled.div`
  margin-top: 7rem;
  border: 1px solid var(--border-color);
  padding: 2rem;
  border-radius: 16px;

  thead {
    padding: 2rem 0;
  }

  th {
    padding-bottom: 25px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 2px;
  }
  td:nth-child(2) {
    text-align: -webkit-center;
    vertical-align: middle;
  }
  td:nth-child(3) {
    text-align: -webkit-center;
    vertical-align: middle;
  }
  td:nth-child(4) {
    text-align: -webkit-center;
    vertical-align: middle;
  }
  tbody tr:last-child {
    /* border-top: 1px solid #fff; */
    border-bottom: #ffffff00;
  }
  @media (max-width: 1024px) {
    margin-top: 3rem;
  }
  @media (max-width: 600px) {
    width: 100%;
    overflow: auto;

    // table {
    //   width: 1200px;
    // }
  }
  @media (max-width: 430px) {
    padding: 10px;
  }
`;
export const WeCompareHeading = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  padding: 15px 0 5px;
  @media (max-width: 430px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const WeCompareInner = styled.div`
  font-size: 13.45px;
  font-weight: 300;
  line-height: 24px;
  padding: 0px 0 15px;
`;

export const ListItems = styled.div`
  ul {
    list-style: unset;
    padding-left: 20px;
  }
`;

export const CEOSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 60vh;

  @media (min-width: 1440px) {
    min-height: 100vh;
  }
  @media (max-width: 1025px) {
    min-height: unset;
  }
`;
export const CEOSectionInner = styled.div`
  position: relative;
  width: 100%;

  svg {
    height: unset !important;
  }
  &.videoSection {
    svg {
      cursor: pointer;
    }
  }

  @media (max-width: 820px) {
    .CEOFirstSection {
      height: 430px;
    }
  }
  @media (max-width: 430px) {
    .CEOFirstSection {
      height: auto;
    }
  }
`;

export const PhoneLetsDrop = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;

  Input {
    border: 0 !important;
  }
  .phoneDrop {
    border: 0;
    width: 60px;
    padding-right: 0;
    background: transparent;
  }
  svg {
    color: #c1c1c1;
    height: 28px;
    min-width: 28px;
  }
  .dropdown-item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .btn:hover {
    color: unset;
    background-color: unset;
    border-color: #fff;
    box-shadow: unset;
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    color: unset;
    background-color: unset;
    border-color: #fff;
    box-shadow: unset;
  }
`;

export const ReadyDesignMain = styled.div`
  /* @media (max-width: 1024px) {
    padding-top: 4.5rem;
  } */
`;

export const ReadyDesignSub = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 0;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0rem 0;

    &.FlutterReadyDesign {
      padding-top: 0rem;
    }
  }
  @media (max-width: 820px) {
    padding: 0rem 0;
    margin-top: 0rem;
    svg.BottomLineSvg {
      width: 70%;
    }
  }
  @media (max-width: 769px) {
    /* gap: 1rem; */
    padding: 0rem 0;
    margin-top: 2rem;

    &.TimeExpand {
      padding: 5rem 0;
    }
  }
  @media (max-width: 600px) {
    /* gap: 1rem !important; */
    margin-bottom: 6.5rem;
    margin-top: 5rem;
  }
`;

export const ReadyDesignH3 = styled.h3`
  font-size: 56px;
  font-weight: 600;
  line-height: 65px;
  text-align: center;
  display: inline;
  position: relative;

  &.frequently {
    padding-top: 15rem;
  }

  @media (max-width: 1024px) {
    font-size: 46px;
    line-height: 60px;

    .design {
      bottom: -24px;
      svg {
        width: 100%;
      }
    }
  }

  @media (max-width: 820px) {
    font-size: 36px;
    line-height: 46px;
  }
  @media (max-width: 769px) {
    font-size: 36px;
    line-height: 46px;
    padding-top: 3rem;
  }
  @media (max-width: 600px) {
    .fullsizesvgHauto {
      top: -22px;
    }
    .oddoinside {
      top: -4px;
    }
  }
  @media (max-width: 430px) {
    font-size: 32px;
    line-height: 40px;
    &.frequently {
      padding-top: 3rem;
    }
    padding-top: 0rem;
  }
`;

export const ReadyDesignP = styled.p`
  font-size: 26px;
  line-height: 36px;
  font-weight: 300;
  text-align: center;
  display: inline;
  position: relative;
  max-width: 760px;
  padding-top: 30px;
  @media (min-width: 1440px) {
    padding-top: 50px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
    max-width: 573px;
    &.beforeText {
      padding-bottom: 3rem;
    }
    /* br {
      display: none;
    } */
  }

  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 26px;
    max-width: 470px;
  }
  @media (max-width: 769px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
    br {
      display: none;
    }
  }
  @media (max-width: 479px) {
    padding-top: 20px;
  }
`;

export const ReadyDesignButton = styled.button`
  background: #ff8e5e;
  background: ${(props) => (props.bg ? props.bg : ``)};
  padding: 10px 15px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  text-transform: uppercase;
  /* margin-top: 3rem; */
  &.getButton {
    background: #ff8e5e;
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }

  @media (max-width: 769px) {
    font-size: 14px;
  }
  @media (max-width: 600px) {
    font-size: 11px !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    gap: 10px;
  }
`;
export const VideoShowMain = styled.div`
  position: relative;

  video {
    width: 100%;
  }
  svg {
    opacity: 0;
    background: #000;
    border-radius: 50%;
    color: #fff;
    padding: 6px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 30px;
    width: 30px;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
export const RFPShowMain = styled.div`
  position: relative;

  .closebtnrfp {
    opacity: 0;
    background: #000;
    border-radius: 50%;
    color: #fff;
    padding: 6px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 30px;
    width: 30px;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
export const ShopifyFaqInside = styled.div`
  @media (max-width: 820px) {
    .shopifyTitle {
      font-size: 22px;
      line-height: 32px;
    }
    .shopifySub {
      font-size: 16px;
      line-height: 26px;
    }
  }

  @media (max-width: 600px) {
    .shopifyIn {
      padding-left: 0;
      padding-right: 0;
    }
    .shopifyFaqItems {
      padding: 1rem !important;
    }
    .shopifyTitle {
      letter-spacing: 0.2px;
      font-size: 18px;
      line-height: 26px;
    }
    .shopifySub {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;
